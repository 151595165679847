
export const AddFavouriteMutation = `
    mutation addFavouriteMutation($input: Int) {
	    addFavourite(placeId: $input) {
            createdDateTime,
            features { title, badgeCount, description, iconUrl },
            detailsUrl,
            marketedBy,
            photosTotal,
            photoUrl,
            placeId,
            price,
            priceModifier,
            title,
            address,
            eCommerceTracking{
              id,
              name,
              brand,
              dimension1,
              category,
              coupon,
              variant,
              price,
              event,
              affiliation
            }
        }
    }
`;
