import { GqlQueryPart } from "src/app/services/graphql.module/model";

export const MeQuery = `
  me{
    fullName,
    email,
    phoneNumber,
    postcode,
    country {key,value},
    employmentStatus {key, value},
    emailPropertyAlerts,
    emailNewsletters,
    emailPartnerOffers,
    userInterests{key,value},
    pushNotifications,
    hasConfirmedEmail,
    userId,
    userType,
    isChangePasswordRequired,
    savedSearches{ searchArea, locationName, latitude, longitude, hasAlerts, hasRecommendations, path, placeSearchCriteriaId, description, badgeCount, shareUrl }
    favourites{
      createdDateTime,
      features { title, badgeCount, description, iconUrl },
      detailsUrl,
      marketedBy,
      photosTotal,
      photoUrl,
      placeId,
      price,
      priceModifier,
      title,
      address,
      eCommerceTracking{
        id,
        name,
        brand,
        dimension1,
        category,
        coupon,
        variant,
        price,
        event,
        affiliation
      }
    },
    campaigns{
      campaignId,
      name,
      type
    },
    placeEnquiries{
      address,
      agentAddress,
      agentName,
      agentPhoneNumber,
      detailsUrl,
      enquiryDate,
      enquiryId,
      enquiryMessage,
      placeId,
      photos,
      postcode,
      price,
      priceModifier,
      title,
      isHidden,
      enquirySubType{
        id,
        lookUpOrder,
        name
      }
    }
  }
`;
export function CreateMeQueryPart(): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: MeQuery,
    arguments: null
  };
  return result;
};