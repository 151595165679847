import { Injectable, EventEmitter, Inject } from "@angular/core";
import { PlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { SearchBarDropDownContent } from "src/app/infrastructure/model/searchBarDropdownContent";
import { PropertyListingsResult } from "src/app/services/search.module/model/propertyListingsResult";
import { Me } from "src/app/infrastructure/model/me";
import { CopyObject } from "src/app/infrastructure/object.helpers";
import { BROWSER_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "src/app/infrastructure/storage.keys";
import { KeyValuePair } from "src/app/infrastructure/types";
import { LocalSettings } from "./model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { MeStateChangedMessageData, SavedSearchesUpdatedMessageData, ShowLoaderMessageData } from "src/app/infrastructure/message.data.types";
import { GqlQueryBuilder } from "../graphql.module/graphql.query.builder";
import { QUERY_HYDRATION_KEYS } from "src/app/infrastructure/query.hydration.keys";
import { RemoveFavouriteMutation } from "src/app/graphql/mutations/RemoveFavouriteMutation";
import { AddFavouriteMutation } from "src/app/graphql/mutations/AddFavouriteMutation";
import { Favourite } from "src/app/infrastructure/model/favourite";
import { GraphQLService } from "../graphql.module/graphql.service";
import { AuthorizationService } from "../authorization.module/authorization.service";
import { Messenger } from "../messenger.module/messenger";
import { BrowserStorageService } from "../browser.storage.module/browser.storage.service";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { TrackingService } from "../tracking.module/tracking.service";
import { environment } from "src/environments/environment";
import { CreateMeQueryPart } from "src/app/graphql/queries/me.query";
import { lookupData } from "../initialization.module/Initialization.data";


@Injectable({providedIn: 'root'})
export class ApplicationStateService {
    private postLoginReturnUrl: string;
    private currentSearchCriteria: PlaceSearchCriteria;
    private dropDownContentSale: SearchBarDropDownContent = new SearchBarDropDownContent();
    private dropDownContentRent: SearchBarDropDownContent = new SearchBarDropDownContent();
    private latestPropertyListingsResult: PropertyListingsResult;
    private latestGeoSearchResult: string;
    private resetRadiusOnNextSearch: boolean = false;
    private shouldApplyMapSearchCriteria: boolean = false;

    me: Me;
    preventListingsSearch: boolean;
    searchDropDownsContentUpdatedEvent = new EventEmitter<void>();
    focusIsOnPopup: boolean = false;
    fixedHeaderHeight: number = 0;
    latestNonPlacebuzzUriParameters: any;
    disableSearchLoader: boolean;
    isFooterHidden: boolean = false;
    isNavBarHidden: boolean = false;
    isInSearchResultsPage: boolean;
    allowPopupInNavigation: boolean = false;
    placeSearchCriteria: PlaceSearchCriteria;

    listingAdTypes: Array<KeyValuePair<number, string>>;

    constructor(
        private gqlService: GraphQLService,
        private authorizationService: AuthorizationService,
        private messenger: Messenger,
        private browserStorageService: BrowserStorageService,
        private platformService: PlatformHelpersService,
        private trackingService: TrackingService
    ) {
        this.InvokeTransferSettingsHook();
        this.currentSearchCriteria = {};
        
        this.dropDownContentRent.listed = lookupData.listed;
        this.dropDownContentRent.numberOfBedrooms = lookupData.rentNumberOfBedrooms;
        this.dropDownContentRent.prices = lookupData.rentPrices;
        this.dropDownContentRent.propertyTypes = lookupData.propertyTypes;
        this.dropDownContentRent.radiuses = lookupData.radiuses;
        this.dropDownContentRent.sortBy = lookupData.sortBy;
        this.dropDownContentRent.priceTypes = lookupData.priceTypes.filter(p => p.listingTypeId === 1);
        
        this.dropDownContentSale.listed = lookupData.listed;
        this.dropDownContentSale.numberOfBedrooms = lookupData.saleNumberOfBedrooms;
        this.dropDownContentSale.prices = lookupData.salePrices;
        this.dropDownContentSale.propertyTypes = lookupData.propertyTypes;
        this.dropDownContentSale.radiuses = lookupData.radiuses;
        this.dropDownContentSale.sortBy = lookupData.sortBy;
        this.dropDownContentSale.priceTypes = lookupData.priceTypes.filter(p => p.listingTypeId === 2);




        if (!this.platformService.IsBrowserPlatform) {
            this.RefreshMeFromServer();
        }
        else {
            this.SetMobileAppTracking();
        }
        
        
    }
    SetSearchDropDowns(forRent: SearchBarDropDownContent, forSale: SearchBarDropDownContent): void {
        this.dropDownContentRent = forRent;
        this.dropDownContentSale = forSale;
    }
    setLatestSearchCriteria(value: PlaceSearchCriteria): void {
        this.placeSearchCriteria = value;
    }
    
    getLatestSearchCriteria(): PlaceSearchCriteria {
        return this.placeSearchCriteria;
    }
    

    get isMobileApp(): boolean {
        return this.platformService.isMobileApp;
    }

    GetShouldResetRadius(): boolean {
        var result = this.resetRadiusOnNextSearch;
        this.resetRadiusOnNextSearch = false;
        return result;
    }

    SetResetRadiusOnNextSearch(): void {
        this.resetRadiusOnNextSearch = true;
    }

    SetShouldApplyMapSearchCriteria(value: boolean): void {
        this.shouldApplyMapSearchCriteria = value;
    }

    GetShouldApplyMapSearchCriteria(): boolean {
        return this.shouldApplyMapSearchCriteria;
    }

    SetLatestSearchArea(value: string): void {
        this.latestGeoSearchResult = value;
    }

    GetLatestSearchArea(): string {
        return this.latestGeoSearchResult;
    }

    private SetMobileAppTracking() {
        var mobileAppVersion = "";
        var mobileAppOs = "";
        if (this.isMobileApp) {
            var mobileAppInfo = this.GetMobileAppInfo();
            if (mobileAppInfo.length > 2) {
                mobileAppOs = mobileAppInfo[0];
                mobileAppVersion = mobileAppInfo[2];
            }
        }
        this.trackingService.SetMobileAppValues(this.isMobileApp, mobileAppVersion, mobileAppOs);
    }

    private InvokeTransferSettingsHook() {
        if (this.platformService.IsBrowserPlatform) {
            try {
                if (ExecuteTransferSettingsHook) {
                    ExecuteTransferSettingsHook(JSON.stringify({
                        apiUrl: environment.apiUrl
                    }));
                }
            }
            catch{ }
        }
    }

    SetLatestSearchResults(results: PropertyListingsResult) {
        this.latestPropertyListingsResult = results;
    }
    GetLatestSearchResults(): PropertyListingsResult {
        if (this.latestPropertyListingsResult) {
            var result = CopyObject(this.latestPropertyListingsResult)
            this.ResetLatestSearchResults();
            return result;
        }
        return undefined;
    }
    ResetLatestSearchResults(): void {
        this.latestPropertyListingsResult = undefined;
    }

    RefreshMeFromServer(): Promise<Me> {
        
        return new Promise(resolve => {
            if (this.platformService.IsBrowserPlatform && this.authorizationService.isLoggedIn) {
                var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.MeQuery);
                queryBuilder.BuildMultiPartQuery([
                    CreateMeQueryPart()
                ]);
                this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.MeQuery, queryBuilder.query, queryBuilder.variables, () => { this.RefreshMeFromServer() }, this, false).then(response => {
                    var me: any = {};
                    if (this.gqlService.ProcessResponse(me, response)) {
                        if (me && me.me && me.me.email) {
                            this.me = me.me;
                            this.StoreRemoteSearchesLocally(this.me.savedSearches);
                            // Update latest search if required
                            var latestSearch: PlaceSearchCriteria = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH);
                            if (latestSearch && this.me.savedSearches) {
                                var latestSavedSearch = this.me.savedSearches.find(s => s.placeSearchCriteriaId === latestSearch.placeSearchCriteriaId);
                                if (latestSavedSearch) {
                                    this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH, latestSavedSearch);
                                }
                            }
                            
                            this.SendMeStateChangedMessage();
                            this.SendSavedSearchesUpdatedMessage();
                            this.SendFavouritesUpdatedMessage();
                        }
                        else {
                            this.ClearMe();
                        }
                    }
                    resolve(me.me);
                });
            }
            else {
                this.ClearMe();
                resolve(null);
            }
        });
    }

    StoreRemoteSearchesLocally(searches: PlaceSearchCriteria[]) {
        this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.REMOTE_SEARCHES, searches);
    }

    UpdateSearchCriteria(kvp: Array<KeyValuePair<any, any>>) {
        for (var i = 0; i < kvp.length; i++) {
            if (kvp[i].value !== undefined) {
                this.currentSearchCriteria[kvp[i].key] = kvp[i].value;
            }
        }
    }

    GetSearchDropDowns(listingType: string): SearchBarDropDownContent {
        switch (listingType) {
            case "rent": return this.dropDownContentRent;
            case "sale": return this.dropDownContentSale;
            default: return null;
        }
    }

    LogoutAndClearMe(): void {
        this.authorizationService.LogoutUser();
        this.ClearMe();
        this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.LOCAL_SEARCHES);
        this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH);
        this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.PENDING_ENQUIRIES);
        this.browserStorageService.RemoveSession(SESSION_STORAGE_KEYS.HAS_TRACKED_LOGGED_IN_SESSION);
       
        
    }

    StorePostLoginReturnUrl(url: string): void {
        this.postLoginReturnUrl = url;
    }

    RetrievePostLoginReturnUrl(): string {
        var url = this.postLoginReturnUrl;
        
        this.postLoginReturnUrl = null;
        return url;
    }

    SaveLocalSettings(settings: LocalSettings): void {
        this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LOCAL_SETTINGS, settings);
    }

    GetLocalSettings(): LocalSettings {
        var result = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LOCAL_SETTINGS);
        if (!result) { result = new LocalSettings(); }
        return result;
    }

    AddToFavourites(placeId): Promise<boolean> {
        return this.ExecuteFavouritesRequest(placeId, true).then();
    }

    RemoveFromFavourites(placeId): Promise<boolean> {
        return this.ExecuteFavouritesRequest(placeId, false);
    }


    private ExecuteFavouritesRequest(placeId: number, isAdd: boolean): Promise<boolean> {
        return new Promise(resolve => {
            var mutationName = isAdd ? AddFavouriteMutation : RemoveFavouriteMutation;
            this.gqlService.ExecuteMutation(mutationName, placeId, () => { this.ExecuteFavouritesRequest(placeId, isAdd) }, this, false).then(r => {
                var favouriteResult = {};
                if (this.gqlService.ProcessResponse(favouriteResult, r)) {
                    if (isAdd) {
                        this.me.favourites.push((<any>favouriteResult).addFavourite as Favourite);
                    }
                    else {
                        var toRemove = this.me.favourites.find(f => f.placeId === placeId);
                        if (toRemove) {
                            this.me.favourites.splice(this.me.favourites.indexOf(toRemove), 1);
                        }
                    }
                    resolve(isAdd);
                    this.SendFavouritesUpdatedMessage();
                }
                else {
                    // Failed so roll back
                    resolve(!isAdd);
                }
            }).catch(() => {
                resolve(!isAdd);
            })
        });
    }

    private GetMobileAppInfo(): string[] {
        // User agent for mobile app looks like: Mozilla/5.0 (iPhone; CPU iPhone OS 11_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E216/Placebuzz.Mob.App-iOS-version-2.0.0.13
        if (this.isMobileApp) {
            var mobileAppUserAgent = this.platformService.Window.navigator.userAgent;
            var split = mobileAppUserAgent.split("Placebuzz.Mob.App");
            if (split.length > 0) {
                return split[split.length - 1].split("-").filter(s => s != "");
            }
        }
        return [];
    }

    private ClearMe() {
        this.me = null;
        
        this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.REMOTE_SEARCHES);
        this.SendMeStateChangedMessage();
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SAVED_SEARCHES_UPDATED,
            messageData: new SavedSearchesUpdatedMessageData(null, [])
        });
        this.messenger.Send({
            messageType: MESSAGE_TYPES.ENQUIRIES_UPDATED,
            messageData: null
        })
        this.SendFavouritesUpdatedMessage();
        this.SendMeStateChangedMessage();
    }

    private SendMeStateChangedMessage() {
        this.messenger.Send({
            messageData: new MeStateChangedMessageData(this.me),
            messageType: MESSAGE_TYPES.ME_STATE_CHANGED
        });
    }

    SendSavedSearchesUpdatedMessage() {
        var searches = new Array<PlaceSearchCriteria>();

        var remoteSearches = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LOCAL_SEARCHES);
        if (remoteSearches) {
            for (var i = 0; i < remoteSearches.length; i++) {
                searches.push(remoteSearches[i]);
            }
        }

        var localSearches = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.REMOTE_SEARCHES);
        if (localSearches) {
            for (var i = 0; i < localSearches.length; i++) {
                searches.push(localSearches[i]);
            }
        }

        var latestSearch = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH);
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SAVED_SEARCHES_UPDATED,
            messageData: new SavedSearchesUpdatedMessageData(latestSearch, searches)
        });
    }

    SendFavouritesUpdatedMessage() {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.FAVOURITES_UPDATED,
            messageData: null
        });
    }

    ShowLoader(isVisible: boolean) {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_LOADER,
            messageData: new ShowLoaderMessageData(isVisible, "")
        });
    }
}
